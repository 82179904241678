<template>
  <div id="ab-test">
    <Headline />
    <AbTestExample />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import AbTestExample from '../../components/ab-test/AbTestExample'

export default {
  name: 'Example',
  components: {
    Headline,
    AbTestExample
  }
}
</script>
