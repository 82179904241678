<template>
  <ab-test candidate-param="ab_test_candidate" default-candidate="B">
    <Hello slot="A" />
    <World slot="B" />
  </ab-test>
</template>

<script>
import Hello from './Hello'
import World from './World'

export default {
  name: 'AbTestExample',
  components: {
    Hello,
    World
  }
}
</script>
